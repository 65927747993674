












import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class AuditSurveyImage extends Vue {
  @Prop({ required: false, default: "", type: String })
  imageSelected: string;

  private closeImage() {
    this.$emit("closed-image");
  }
}
